
import firebase from "firebase/app";
import config from "@/services/configFirebase";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    loading: false,
    success: false,
    error: null
  },
  mutations: {

    requestSuccessStatus(state, status) {
      state.success = status;
    },
    requestFailureStatus(state, status) {
      state.error = status;
    },
    showBtnLoading(state, status) {
      state.loading = status;
    },        
  },
  actions: { 

    async changePassword(context,{oldPassword,newPassword}) {

        context.commit('showBtnLoading',true)
        const user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            oldPassword
        );
        await  user.reauthenticateWithCredential(credential).then(() =>{
                // User re-authenticated.  
                console.log('Success')         
                user.updatePassword(newPassword).then(() => {
                    firebase.auth().signOut().then(() => {
                      //context.commit('requestSuccessStatus','Password Changed Successfully')
                      context.commit('showBtnLoading',false)
                      Vue.$toast.success('Password Changed Successfully. Please Sign in with new password.')
                      this.$router.push('/signIn')
                    }).catch((error) => {
                      console.log(error.message)
                    });
                }).catch((err) => {
                    context.commit('requestFailureStatus',err.message)
                    context.commit('showBtnLoading',false)
                    console.log(err.message)
                })

            }).catch((err) => {
                console.log(err)
                context.commit('requestFailureStatus','Old password is invalid')

                context.commit('showBtnLoading',false)

            });                
    
            // track change password attempt
            config.db.collection('change_password_track').add({
            email: user.email,
            attempt_date: new Date()
            }).then(() => {
                console.log("Attempt successfully added!")
            }).catch((error) => {
                console.log("Error:",error)
            })
        


    },
    resetDialog(context)
    {
      context.commit('requestFailureStatus', null)
    }
  },
};
