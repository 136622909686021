import localDbService from "@/services/localDbService";
import localForageService from "@/services/localForageService";
import firebase from "firebase/app";
import Vue from "vue";
import router from "../../../router";
import firestore from "@/services/configFirebase";
export default {
  state: {
    loggedInUser: null,
  },
  mutations: {
    mutateLoggedInUser(state, user) {
      state.loggedInUser = user;
    },
  },
  actions: {
    signOut(context) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // window.indexedDB.deleteDatabase("greaves");
          // clear(context).then(() => {
          //   router.replace({ name: "SignIn" });
          // });
          localForageService.clearData(context).then(() => {
            router.replace({ name: "SignIn" });
          });
          // return this.clearLocalData();
        });
    },
    // clearLocalData(context) {
    //   clear(context);
    // },
    signInRequest(context, userLogin) {
      context.commit("showLoader", "Sign in, please wait...");
      firebase
        .auth()
        .signInWithEmailAndPassword(userLogin.user.email, userLogin.password)
        .then(async (data) => {
          if (data.user.emailVerified) {
            console.log(userLogin.user.email)
            let usersSnapList = await firestore.db.collection("Users").where("email", "==", userLogin.user.email).get();
            let userList = usersSnapList.docs.map((data) => ({ id: data.id, ...data.data() }));
            console.log(userList);
            if (userList.length > 0) {
              userList[0].role = userList[0].apps;
              if(userList[0].oemCode==null && userList[0].dealerCode!=null){
                userList[0].oemCode = userList[0].dealerCode;
              }
              userList[0].customer = userList[0].oemCode;
              localDbService
                .setItem("loggedInUser", userList[0])
                .then(() => {
                  console.log("saved user : " + userList[0].role);
                  context.commit("showLoader", null);
                  router.push({ name: "OemDashboard" });
                });
            } else {
              Vue.$toast.error("User not found ");
            }
          } else {
            Vue.$toast.error("Invalid User Please check ");
          }
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error(error);
          context.commit("showLoader", null);
        });
    },
  },
};
