import localDbService from "@/services/localDbService";
import firebase from "@/services/configFirebase";

const isFile = input => 'File' in window && input instanceof File;

export default {
  state: {
    dealer_claims: [],
    promotional_claims: [],
    grn_claims: [],
    inventory_list: [],
    states: [],
    hsn_code: [],
    material_types: [],
    inventory_request: [],
    oem_list: [],
    current_oem: null
  },
  mutations: {
    mutateinventories(state, inventory_list) {
      state.inventory_list = inventory_list;
    },
    mutatestates(state, states) {
      state.states = states
    },
    mutatehsn_code(state, hsn_code) {
      state.hsn_code = hsn_code
    },
    mutateorder_types(state, material_types) {
      state.material_types = material_types
    },
    mutateinventory_request(state, inventory_request) {
      state.inventory_request = inventory_request
    },
    mutateOems(state, oem_list) {
      state.oem_list = oem_list
    },

    mutateCurrentOem(state, current_oem) {
      state.current_oem = current_oem
    },
  },
  getters: {
    // filterPendingDealerClaims(state, getters, rootState) {
    //   let claims = state.dealer_claims.filter(
    //     (x) =>
    //       x.assigned_to == rootState.authController.loggedInUser.email &&
    //       (x.claim_component[0].status == "Pending" ||
    //         x.claim_component[0].status == "Escalation_1" ||
    //         x.claim_component[0].status == "Escalation_2")
    //   );

    //   claims.sort(function (a, b) {
    //     return new Date(b.created_at) - new Date(a.created_at);
    //   });
    //   return claims;
    // },
    // filterPendingServices(state, getters, rootState) {
    //   let claims= state.services.filter((x) => x.status == "Pending Claim Approval");
    //   claims.sort(function (a, b) {
    //     return new Date(b.created_at) - new Date(a.created_at);
    //   });
    //   return claims;
    // },
    // filterPendingPromotionalClaims(state, getters, rootState) {
    //   let claims= state.promotional_claims.filter(
    //     (x) =>
    //       x.assigned_to != null &&
    //       x.assigned_to.includes(rootState.authController.loggedInUser.email) &&
    //       (x.status == "Pending Claim Approval" ||
    //         x.status == "Pending Settlement")
    //   );
    //   claims.sort(function (a, b) {
    //     return new Date(b.created_at) - new Date(a.created_at);
    //   });
    //   return claims;
    // },
    // filterPendingGrnClaims(state) {
    //   let claims= state.grn_claims.filter(
    //     (x) =>
    //       x.status == "Pending Approval" || x.status == "Pending Settlement"
    //   );
    //   claims.sort(function (a, b) {
    //     return new Date(b.created_at) - new Date(a.created_at);
    //   });
    //   return claims;
    // },
  },
  actions: {
    getStates() {
      localDbService.getRows("states");
    },

    getHsnCode() {
      localDbService.getRows("hsn_code");
    },

    getMaterialTypes() {
      localDbService.getRows("order_types");
    },

    getOemList({ commit }) {
      //localDbService.getRows("Oems");
      return new Promise((resolve) => {
        firebase.db.collection("Oems").orderBy('oemCode', 'asc')
          .get()
          .then((querySnapshot) => {
            const documents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            //querySnapshot.forEach((doc) => {
            commit('mutateOems', documents)
            //console.log(`${doc.id}`);
            resolve()
            // });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      })
    },

    setCurrentOem({ commit, state }, oem) {
      return new Promise((resolve) => {
        let current_oem = state.oem_list.filter(
          (x) =>
            x.oemCode == oem
        );
        console.log(current_oem)
        commit('mutateCurrentOem', current_oem)
        resolve()
      });
    },
    getInventoryList({ commit, state, rootState }, category) {
      console.log('cat', category)

      let currentUser = rootState.authController.loggedInUser
      let oemCode = currentUser.userType == 'OemMaster' ? state.current_oem[0].oemCode : currentUser.oemCode;
      return new Promise((resolve) => {
        console.log('inve...');
        firebase.db.collection("inventories").where("active", "==", true).where("oem.oemCode", "==", oemCode).where("vehicleType", "==", category)
          .orderBy('updated_at', 'desc')
          .get()
          .then((querySnapshot) => {
            let documents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            //querySnapshot.forEach((doc) => {
            documents = documents.filter((document) => {
              if (document.materialDesc) {
                if (document.vehicleType != "Spare Parts") {
                  let materialDescSplit = document.materialDesc.split("-");
                  if (materialDescSplit.length >= 2) {
                    return true;
                  }
                }
                else {
                  return true;
                }
              }
            
            });
            console.log(oemCode + " inventories list - ", documents.length);
            commit('mutateinventories', documents)
            //  console.log(`${doc.id}`);
            resolve()
            // });

          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      });
      // return new Promise((resolve, reject) => {
      //   localDbService.getRows("inventories",[["oem.oemCode", oemCode]]).then(() => {
      //     resolve(true);
      //   });
      // });
    },
    getInventoryRequestList({ commit, state, rootState }) {
      let currentUser = rootState.authController.loggedInUser
      let oemCode = currentUser.userType == 'OemMaster' ? state.current_oem[0].oemCode : currentUser.oemCode
      return new Promise((resolve) => {
        firebase.db.collection("inventory_request").where("oemCode", "==", oemCode)
          .orderBy("created_at", "desc")
          .get()
          .then((querySnapshot) => {
            const documents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            //querySnapshot.forEach((doc) => {
            commit('mutateinventory_request', documents)
            // console.log(`${doc.id}`);
            resolve()
            //});
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      });
      // return new Promise((resolve, reject) => {
      //   localDbService.getRows("inventory_request",[["oemCode", oemCode]]).then(() => {
      //     resolve(true);
      //   });
      // });
    },

    updateMaterialQty(context, materialData) {
      materialData.availableQuantity = Number(materialData.availableQuantity);
      let found = false;
      for (let aQ of materialData.availableQuantityList) {
        if (aQ.oemCode == materialData.oemCode) {
          found = true;
          aQ.availableQuantity = Number(materialData.availableQuantity);
          aQ.oemCity = materialData.oemCity;
        }
      }
      if (!found) {
        materialData.availableQuantityList.push({ oemCode: materialData.oemCode, oemCity: materialData.oemCity, availableQuantity: Number(materialData.availableQuantity) });
      }
      let materialRef = firebase.db.collection("inventories").doc(`${materialData.id}`);
      return materialRef.update({
        updated_at: new Date(),
        availableQuantityList: materialData.availableQuantityList,
      })
        .then(() => {
          context.dispatch('getInventoryList');
          console.log("Material qty updated successfully!");
          firebase.db.collection('material_qty_update_track').add({
            material_code: materialData.materialNumber,
            old_qty: materialData.old_qty,
            new_qty: materialData.availableQuantity,
            oemCode: materialData.oemCode,
            created_at: new Date()
          }).then(() => {
            console.log("Attempt successfully added!")
          }).catch((error) => {
            console.log("Error:", error)
          })
        }).catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating Material qty: ", error);
        });

    },
    processInventoryUpload(context, materialData) {
      let inventoryRef = firebase.db.collection("Materials").doc(`${materialData.materialNumber}`);
      inventoryRef.get().then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });

      if (inventoryRef)
        inventoryRef.set({
          materialDesc: materialData.materialDesc,
          availableQuantity: materialData.availableQuantity,
          updatedAt: new Date(),
        },
          { merge: true }
        )
          .then(() => {
            context.dispatch('getInventoryList');
            console.log("Material updated successfully!");

          }).catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating Material: ", error);
          });

    },
    async addNewInventory({ dispatch, commit, rootState }, newInventory) {
      console.log('user', rootState.authController.loggedInUser)
      let currentUser = rootState.authController.loggedInUser
      let oemCode = currentUser.userType == 'OemMaster' ? state.current_oem[0].oemCode : currentUser.oemCode
      let oem = currentUser.userType == 'OemMaster' ? state.current_oem[0].oem : currentUser.type
      new Promise((resolve) => {
        firebase.storageRef.child('oem_material_images/' + newInventory.material_number + '.jpg')
          .put(newInventory.product_img_url);
        resolve();
      });
      let product_img_url = null
      if (newInventory.product_img_url != null && isFile(newInventory.product_img_url)) {
        await dispatch('imageUploadMaterial', {
          image: newInventory.product_img_url,
          material_number: newInventory.material_number
        }).then(
          (url) => {
            product_img_url = url;
          }
        );
      }
      firebase.db.collection('inventory_request').doc(newInventory.request_number).set({
        request_number: newInventory.request_number,
        material_number: newInventory.material_number,
        material_type: newInventory.material_type,
        category: newInventory.category,
        model_name: newInventory.model_name,
        material_description: newInventory.material_description,
        hsn_code: newInventory.hsn_code,
        color: newInventory.color,
        states: newInventory.selected_states,
        price: newInventory.price,
        request_type: 'Material Creation',
        status: 'Pending',
        oemCode: oemCode,
        oem: oem,
        product_img_url: product_img_url,
        created_at: new Date()
      }).then(() => {
        console.log("New Inventory added successfully!")
      }).catch((error) => {
        console.log("Error:", error)
      })
    },

    async updateInventoryRequest({ state, dispatch, commit, rootState }, updateInventory) {
      let currentUser = rootState.authController.loggedInUser
      let oemCode = currentUser.userType == 'OemMaster' ? state.current_oem[0].oemCode : currentUser.oemCode
      let oem = currentUser.userType == 'OemMaster' ? state.current_oem[0].oem : currentUser.type
      console.log('user', rootState.authController.loggedInUser)
      console.log('detail', updateInventory)
      let product_img_url = null
      if (updateInventory.product_img_url != null && isFile(updateInventory.product_img_url)) {
        await dispatch('imageUploadMaterial', {
          image: updateInventory.product_img_url,
          material_number: updateInventory.material_number
        }).then(
          (url) => {
            product_img_url = url;
          }
        );
      }
      firebase.db.collection('inventory_request').doc(updateInventory.request_number).set({
        request_number: updateInventory.request_number,
        material_number: updateInventory.material_number,
        material_type: updateInventory.material_type,
        category: updateInventory.category,
        model_name: updateInventory.model_name,
        material_description: updateInventory.material_description,
        hsn_code: updateInventory.hsn_code,
        color: updateInventory.color,
        states: updateInventory.selected_states,
        new_states: updateInventory.new_selected_states,
        price: updateInventory.price,
        new_price: updateInventory.new_price,
        baseRetailPrice: updateInventory.baseRetailPrice,
        request_type: 'Detail Updation',
        status: 'Pending',
        oemCode: oemCode,
        oem: oem,
        product_img_url: product_img_url,
        created_at: new Date()
      }).then(() => {
        console.log("New Inventory added successfully!")
      }).catch((error) => {
        console.log("Error:", error)
      })
    },
    async saveAndUpdateInventory({ state, dispatch, commit, rootState }, updateInventory) {
      let currentUser = rootState.authController.loggedInUser
      let oemCode = currentUser.userType == 'OemMaster' ? state.current_oem[0].oemCode : currentUser.oemCode
      let oem = currentUser.userType == 'OemMaster' ? state.current_oem[0].oem : currentUser.type
      console.log('user', rootState.authController.loggedInUser)
      console.log('detail', updateInventory)
      let product_img_url = null
      if (updateInventory.product_img_url != null && isFile(updateInventory.product_img_url)) {
        await dispatch('imageUploadMaterial', {
          image: updateInventory.product_img_url,
          material_number: updateInventory.material_number
        }).then(
          (url) => {
            product_img_url = url;
          }
        );
      }

      firebase.db.collection('inventories').doc(updateInventory.material_number).update({
        //        states: updateInventory.selected_states,
        //        new_states: updateInventory.new_selected_states,
        //        new_price: updateInventory.new_price,
        material_type: updateInventory.material_type,
        category: updateInventory.category,
        color: updateInventory.color,
        product_img_url: product_img_url,
        updated_at: new Date()
      }).then(() => {
        console.log("New Inventory updated successfully!")
      }).catch((error) => {
        console.log("Error:", error)
      })
    },
    approveRequest(context, request_detail) {
      return new Promise(resolve => {
        firebase.db.collection("inventories").doc("" + request_detail.material_number).get().then((inventorySnapshot) => {
          let inventory = inventorySnapshot.data();
          if (inventory) {
            firebase.db.collection("inventory_request").doc(`${request_detail.id}`).update({
              status: 'Approved',
              updated_at: new Date(),
            }, { merge: true })
              .then(() => {
                let inventoryRef = firebase.db.collection("inventories").doc(`${request_detail.material_number}`);
                if (!request_detail.new_price) {
                  request_detail.new_price = request_detail.price;
                }
                inventoryRef.update({
                  category: request_detail.category,
                  hsnCode: request_detail.hsn_code,
                  materialDesc: request_detail.material_description,
                  vehicleType: request_detail.material_type,
                  model: request_detail.model_name,
                  color: request_detail.color,
                  product_img_url: request_detail.product_img_url,
                  baseRetailPrice: Number(request_detail.new_price),
                  // price: Number(request_detail.new_price),
                  // states: request_detail.new_states,
                  oem: {
                    oem: request_detail.oem,
                    oemCode: request_detail.oemCode
                  },
                  updated_at: new Date(),
                })
                  .then(() => {
                    context.dispatch('getInventoryRequestList');
                    console.log('Inventory successfully updated')
                  }).catch((error) => {
                    console.error("Error updating Request: ", error);
                  });
                context.dispatch('getInventoryRequestList');
                console.log('Request successfully updated')
                resolve({ status: "SUCCESS", message: "Request Approved successfully" });
              }).catch((error) => {
                resolve({ status: "ERROR", message: error });
              });

          } else {
            resolve({ status: "ERROR", message: "Material Not available, please ask DMS to add material first" });
          }
        })

      });
    },
    rejectRequest(context, request_detail) {
      let reuqestref = firebase.db.collection("inventory_request").doc(`${request_detail.id}`);
      return reuqestref.update({
        status: 'Rejected',
        updated_at: new Date(),
      })
        .then(() => {
          context.dispatch('getInventoryRequestList');
          console.log('Request successfully updated')
        }).catch((error) => {
          console.error("Error updating Request: ", error);
        });

    },
    updateInventoryStatus(context, request_detail) {
      let reuqestref = firebase.db.collection("inventory_request").doc(`${request_detail.id}`);
      return reuqestref.update({
        status: request_detail.status,
        updated_at: new Date(),
      })
        .then(() => {
          context.dispatch('getInventoryRequestList');
          console.log('Request successfully updated')
        }).catch((error) => {
          console.error("Error updating Request: ", error);
        });

    },

    imageUploadMaterial(context, { image: image, material_number: material_number }) {
      console.log(image)
      return new Promise((resolve) => {
        let timstm = Date.now()
        let file_path = 'oem_material_images/' + material_number + '.jpg';
        var uploadTask = firebase.storageRef.child(file_path).put(image);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          `state_changed`,
          (snapshot) => {
            // Get task progress,
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");

          },
          (error) => {
            console.log("-- Error in uploading ---", error.message);
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at", downloadURL);
              resolve(downloadURL);
            });
          }
        );
      });
    },
  },


};
