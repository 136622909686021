import localforage from "localforage";
export const localForageService = localforage.createInstance({
  name: process.env.VUE_APP_NAME,
  version: 1.0,
  storeName: "oem",
});

function getItem(key) {
  // console.log("getItem : " + key);
  return new Promise((resolve) => {
    localForageService
      .setDriver([
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE,
      ])
      .then(() => {
        localForageService.getItem(key).then((value) => {
          resolve(value);
        });
      })
      .catch((error) => {
        // welp. you can't have nice things.
      });
  });
}
function setItem(key, value) {
  // console.log("setItem : " + key);
  return new Promise((resolve) => {
    localForageService
      .setDriver([localforage.INDEXEDDB])
      .then(() => {
        localForageService.setItem(key, value).then((value) => {
          resolve(value);
        });
      })
      .catch((error) => {
        console.error("Error in setItem : " + error);
      });
  });
}
function clearData() {
  return new Promise((resolve) => {
    localForageService.setDriver([localforage.INDEXEDDB])
      .then(() => {
        localForageService.clear().then(function () {
          console.log('Database is now empty.');
          resolve();
        }).catch(function (err) {
          // This code runs if there were any errors
          console.log(err);
        });
      })    
  });_  
}
export default {
  getItem,
  setItem,
  clearData
};
