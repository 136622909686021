
import firebase from "firebase/app";
import config from "@/services/configFirebase";

export default {
  namespaced: true,
  state: {
    loading: false,
    success: false,
    error: null
  },
  mutations: {

    requestSuccessStatus(state, status) {
      state.success = status;
    },
    requestFailureStatus(state, status) {
      state.error = status;
    },
    showBtnLoading(state, status) {
      state.loading = status;
    },        
  },
  actions: {
 
    resetPassword(context,email) {
      context.commit('showBtnLoading',true)
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {

            context.commit('requestSuccessStatus',true)
            context.commit('showBtnLoading',false)
            // track forget password attempt
            config.db.collection('forget_password_track').add({
              email: email,
              attempt_date: new Date()
            }).then(() => {
                console.log("Attempt successfully added!")
            }).catch((error) => {
                console.log("Error:",error)
            }) 
        })
        .catch((err) => {
            context.commit('requestFailureStatus',err.message)
            context.commit('showBtnLoading',false)
            console.log(err)
        });

    },
    resetData(context)
    {
      context.commit('requestSuccessStatus', null)
      context.commit('requestFailureStatus', null)
    }    
  },
};
