import Loader from "@/components/Loader";
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  created() {
    document.title = "OEM-"+process.env.VUE_APP_NAME;
    this.$store.commit("showLoader", null);
    //console.log("showLoader")
  },
  
  beforeMount() {
    //console.log("showLoader");
  },
  methods: {
    ...mapActions(["updateLastSync"]),
  },
  components: {
    Loader,
  },
};
