import axios from "axios";
import localDbService from "@/services/localDbService";
import firebase from "@/services/configFirebase";
import firestoreService from "@/services/firestoreService";
export default {
  state: {
    dealer_claims: [],
    promotional_claims: [],
    grn_claims: [],
    services: [],
  },
  mutations: {
    mutateservices(state, services) {
      state.services = services;
    },
    mutatedealer_claims(state, dealer_claims) {
      console.log("mutatedealer_claims : " + dealer_claims.length);
      state.dealer_claims = dealer_claims;
    },
    mutatepromotional_claims(state, promotional_claims) {
      state.promotional_claims = promotional_claims;
    },
    mutategrn_claims(state, grn_claims) {
      console.log("mutategrn_claims : ", grn_claims);
      state.grn_claims = grn_claims;
    },
  },
  getters: {
    filterPendingDealerClaims(state, getters, rootState) {
      let claims = state.dealer_claims.filter(
        (x) =>
        //x.assigned_to == rootState.authController.loggedInUser.email &&
        (x.claim_component[0].status == "OEM Pending Approval" //||
          // x.claim_component[0].status == "Escalation_1" ||
          // x.claim_component[0].status == "Escalation_2"
        )
      );

      claims.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return claims;
    },
    filterPendingServices(state, getters, rootState) {
      let claims = state.services.filter((x) => x.status == "Pending Claim Approval");
      claims.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return claims;
    },
    filterPendingPromotionalClaims(state, getters, rootState) {
      let claims = state.promotional_claims.filter(
        (x) =>
          x.assigned_to != null &&
          x.assigned_to.includes(rootState.authController.loggedInUser.email) &&
          (x.status == "Pending Claim Approval" ||
            x.status == "Pending Settlement")
      );
      claims.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return claims;
    },
    filterPendingGrnClaims(state, getters, rootState) {

      let claims = state.grn_claims.filter(
        (x) =>
          // x.assigned_to.includes(rootState.authController.loggedInUser.email)
          x.assigned_to.includes(rootState.authController.loggedInUser.email)
      );
      claims.sort(function (a, b) {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      return claims;
    },
  },
  actions: {
    getGrnClaims({ commit, state, rootState }) {
      let currentUser = rootState.authController.loggedInUser
      let oemCode = currentUser.oemCode
      //localDbService.getRows("grn_claims");
      return new Promise((resolve, reject) => {
        localDbService.getRows("grn_claims", [["oemCode", oemCode]]).then(() => {
          resolve(true);
        });
      });
    },
    getPromotionalClaims() {
      localDbService.getRows("promotional_claims");
    },
    getDealerClaimsList({ commit, state, rootState }) {
      let currentUser = rootState.authController.loggedInUser
      let oemCode = currentUser.oemCode;
      //localDbService.getRows("dealer_claims");
      return new Promise((resolve, reject) => {
        localDbService.getRows("dealer_claims", [["oem_code", oemCode]]).then(() => {
          resolve(true);
        });
      });
    },
    getServices() {
      return new Promise((resolve, reject) => {
        localDbService.getRows("services").then(() => {
          resolve(true);
        });
      });
    },
    async updateDealerClaims(context, dealer_claim) {
      console.log("updateDealerClaims");
      let attachment_url = []

      // if (dealer_claim.claim_component[0].remarks.attachment.length > 0)
      // {
      //   for (var i = 0; i < dealer_claim.claim_component[0].remarks.attachment.length; i++) {
      //     await context.dispatch('imageUploadClaim', { image: dealer_claim.claim_component[0].remarks.attachment[i] }).then(
      //       (url) => {
      //         attachment_url = url;
      //       }
      //     );
      //   }        
      // }
      // dealer_claim.claim_component[0].remarks.attachment = attachment_url;
      //console.log(dealer_claim);
      return new Promise((resolve, reject) => {
        localDbService.saveInTable("dealer_claims", dealer_claim).then(() => {
          resolve(true);
        });
      });
    },

    updateServiceClaims({ }, service) {
      console.log("updateServiceClaims");
      return new Promise((resolve, reject) => {
        localDbService.saveInTable("services", service).then(() => {
          resolve(true);
        });
      });
    },
    updatePromotionalClaims({ }, promotional_claims) {
      console.log("promotional_claims");
      return new Promise((resolve, reject) => {
        localDbService
          .saveInTable("promotional_claims", promotional_claims)
          .then(() => {
            resolve(true);
          });
      });
    },
    async updateGrnGradeAnswer(context, claim) {
      return new Promise((resolve, reject) => {
        console.log(claim);
        let refId;
        if (!claim.SERIAL_NO) {
          refId = claim.materialNumber + "_" + claim.invoic_number;;
        }
        else {
          refId = claim.SERIAL_NO;
        }
        firestoreService.query("GradeAnswer", [["refId", claim.refId], ["useLocation", claim.useLocation]]).then((gradeList) => {
          console.log('gradeList ', gradeList)
          if (gradeList.length > 0) {
            let gradeAnswer = gradeList[0];
            let questionWithProblems = gradeAnswer.questionList.filter(x => x.answer != x.correct_answer);
            for (let pblms of questionWithProblems) {
              pblms.solution = claim.solution;
            }
            firestoreService.saveObject("GradeAnswer", gradeAnswer).then(() => {
              console.log('gradeAnswer saved successfully');
              firestoreService.query("dealer_inventories", [["SERIAL_NO", claim.SERIAL_NO]]).then((stockList) => {
                console.log('stockList ', stockList)
                if (stockList.length > 0) {
                  let stock = stockList[0];
                  stock.solutionAvailable = claim.solution;
                  firestoreService.saveObject("dealer_inventories", stock).then(() => {
                    resolve(true);
                    console.log('stock saved successfully');
                  })
                }
              });
            });
          } else {
            resolve(false);
          }
        })
      })


    },
    async updateGrnClaims(context, claim_update) {
      let grn_claims = firebase.db.collection("grn_claims").doc(`${claim_update.id}`);
      if (claim_update.remarks == "Approved") {
        grn_claims.promo = {};

      }

      return grn_claims.update(claim_update)
        .then(() => {
          context.dispatch('getGrnClaims');
          console.log('GRN claim successfully updated')
        }).catch((error) => {
          console.error("Error updating GRN claim: ", error);
        });
    },
    async updateGrnClaimTransaction(context, claim_update) {
      console.log('oem_status', claim_update)
      let grn_claims = firebase.db.collection("grn_claims").doc(`${claim_update.id}`);
      return grn_claims.update({
        status: claim_update.status,
        transaction_id: claim_update.transaction_id,
        transaction_date: new Date(claim_update.transaction_date),
        updated_at: new Date(),
      })
        .then(() => {
          context.dispatch('getGrnClaims');
          console.log('GRN claim successfully updated')
        }).catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating GRN claim: ", error);
        });

    },
    fetch_promotion_escalation_rule(context) {
      return new Promise((resolve) => {
        axios
          .get(
            "https://script.google.com/macros/s/AKfycbxRT1inLiOEaVYWD5RHUkqX24f5UAkI1J_vZkUpzFjW_2l7PnibU6OzyK_-068qTKt3/exec"
          )
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => console.log(error));
      });
    },
    fetch_services_by_chessis_number(context, chassis_number) {
      firebase.db
        .collection("services")
        .where("chassis_number", "==", chassis_number)
        .get()
        .then(function (querySnapshot) {
          let services = [];
          querySnapshot.forEach(function (service) {
            let doc_id = service.id;
            service = service.data();
            service.id = doc_id;
            service.service_date = new Date(
              service.service_date.seconds * 1000
            );
            services.push(service);
          });
          services = services.sort((a, b) => {
            return new Date(b.service_date) - new Date(a.service_date);
          });
          context.commit("SET_SERVICES_DATA", services);
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    },
    imageUploadClaim(context, { image: image }) {
      console.log(image)
      return new Promise((resolve) => {

        let timstm = Date.now()
        let file_path = 'invoice_detail/' + timstm + '/' + image.name;
        var uploadTask = firebase.storageRef.child(file_path).put(image);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          `state_changed`,
          (snapshot) => {
            // Get task progress,
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");

          },
          (error) => {
            console.log("-- Error in uploading ---", error.message);
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at", downloadURL);
              resolve(downloadURL);
            });
          }
        );
      });
    },
  },
};
