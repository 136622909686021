import firebase from '@/services/configFirebase';
import firestoreService from '@/services/firestoreService';
import localDbService from '@/services/localDbService';
import firebase2 from 'firebase/app';
import Vue from 'vue';

export default {
  state: {
    orderList: [],
    orders_startAfter: {},
    order_detail: null,
  },
  mutations: {
    mutateorders(state, orderList) {
      let oldList = state.orderList.filter((order) => {
        let found = orderList.find((x) => x.id == order.id);
        if (found == null) {
          return true;
        }
      });
      console.log(
        "*" +
        oldList.length +
        " mutateorders : " +
        orderList.length +
        " : " +
        state.orderList.length
      );
      orderList.forEach((order) => {
        oldList.push(order);
      });
      state.orderList = oldList;
    },
    updateSingleOrder(state, order) {
      let oldOrder = state.orderList.find((x) => x.id == order.id);
      if (oldOrder != null) {
        state.orderList.splice(state.orderList.indexOf(oldOrder), 1, order);
      } else {
        state.orderList.unshift(order);
      }
    },
    orders_startAfterMutation(state, order) {
      state.orders_startAfter = order;
    },
    setOrderDetail(state, order_detail) {
      state.order_detail = order_detail
    }
  },
  getters: {
    pending_orders(state) {
      if (state.orderList != null && state.orderList.length > 0) {
        let sorted = state.orderList.filter(
          (x) => x.order_status == "Pending Approval"
        );
        return sorted.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
      } else {
        return [];
      }
    },
    sortedOrders(state) {
      return state.orderList.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    },
  },
  actions: {
    // fetchOrderHierarchy(context, email) {
    //   return new Promise((resolve) => {
    //     axios
    //       .get(
    //         "https://script.google.com/macros/s/AKfycbwcLmPJztu_uDxwPaW7gzGza1vqyKnAKxllPA3P1tpSd09u3XsZgTJQ5arP8S4MSG0S_A/exec?version=1&approver_email=" +
    //           email
    //       )
    //       .then((response) => {
    //         resolve(response);
    //       });
    //   });
    // },
    getOrdersList(context, searchArray) {
      let currentUser = context.rootState.authController.loggedInUser
      let oemCode = currentUser.oemCode

      return new Promise((resolve) => {
        let whereList = [];
        whereList.push(["vendorCode", oemCode])
        if (searchArray[0].length > 0) {
          whereList.push(searchArray[0]);
        }
        if (searchArray.length >= 2) {
          whereList.push(searchArray[1]);
        }
        if (searchArray.length == 3) {
          whereList.push(searchArray[2]);
        }
        // whereList.push(["bbPoNumber", 0, ">"]);
        console.log('wheredata', whereList)
        firestoreService
          .query(
            "oem_orders",
            whereList,
            "salesOrderNumber",
            true,
            100,
            context.state.orders_startAfter
          )
          .then((orderList) => {
            console.log('oem_orders', orderList)
            resolve(orderList);
          });

      });
    },
    orderUpdate({ }, order) {
      console.log("orderUpdate");
      return new Promise((resolve, reject) => {
        localDbService.saveInTable("oem_orders", order).then(() => {
          resolve(true);
        });
      });
    },

    getOrderDetail({ commit }, order_id) {
      let docRef = firebase.db.collection("oem_orders").doc(order_id);
      docRef.get().then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          commit('setOrderDetail', { ...doc.data() })
        } else {
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    },
    async updateOemOrderDispatchDate(context, selectedOrder) {
      //console.log("Order details",orderDetail);
      firebase.db.collection("oem_orders")
        .doc(`${selectedOrder.oemPurchaseOrderNumber}`)
        .set({
          dispatch_date: selectedOrder.dispatch_date,
          status: 'Pending Invoice',
        }, { merge: true })
        .then(() => {

        })
        .catch((error) =>
          console.error("Error adding order document: ", error)
        );

      if (selectedOrder.salesOrderNumber) {

        const snapdata = await firebase.db.collection("orders")
          .where("sap_order_number", "==", selectedOrder.salesOrderNumber)
          .get();
        snapdata.forEach(doc => {
          //console.log("bb_order_number",doc.data()) ;              
          if (doc.data().order_status == "Pending Invoice") {
            let id = doc.data().bb_order_number;
            firebase.db.collection("orders").doc(`${id}`).set({ dispatch_date: selectedOrder.dispatch_date, order_status: 'OEM Acknowledged' }, { merge: true }).then(() => {
              console.log("Pending Invoice");
            });
          }
        })

      }
    },
    async updateOemOrderDetails(context, orderDetail) {
      //console.log("Order details",orderDetail);
      firebase.db.collection("oem_orders")
        .doc(`${orderDetail.oemPurchaseOrderNumber}`)
        .set({
          status: 'In Transit',
          total_invoiced_qty: orderDetail.total_qty,

        }, { merge: true })
        .then(() => {

        })
        .catch((error) =>
          console.error("Error adding order document: ", error)
        );


      if (orderDetail.salesOrderNumber) {

        const snapdata = await firebase.db.collection("orders")
          .where("sap_order_number", "==", orderDetail.salesOrderNumber)
          .get();
        snapdata.forEach(doc => {
          //console.log("bb_order_number",doc.data()) ;              
          let id = doc.data().bb_order_number;

          firebase.db.collection("orders").doc(`${id}`).set({ total_invoiced_qty: orderDetail.total_qty, oemPurchaseOrderNumber: orderDetail.oemPurchaseOrderNumber }, { merge: true }).then(() => {
            console.log("Order Invoiced");
          });
        })

      }

    },
    async addInvoiceDetail(context, invoiceDetail) {
      console.log(invoiceDetail)
      let uploadedUrls = [];
      for (var i = 0; i < invoiceDetail.attachments.length; i++) {
        await context.dispatch('imageUpload', { image: invoiceDetail.attachments[i] }).then(
          (url) => {
            uploadedUrls.push(url);
          }
        );
      }
      Vue.$toast.success("Invoice images uploaded");
      let eway_uploadedUrls = [];
      for (var i = 0; i < invoiceDetail.eway_attachments.length; i++) {
        await context.dispatch('imageUpload', { image: invoiceDetail.eway_attachments[i] }).then(
          (url) => {
            eway_uploadedUrls.push(url);
          }
        );
      }
      Vue.$toast.success("EWay images uploaded");
      let lr_uploadedUrls = [];
      for (var i = 0; i < invoiceDetail.lr_attachments.length; i++) {
        await context.dispatch('imageUpload', { image: invoiceDetail.lr_attachments[i] }).then(
          (url) => {
            lr_uploadedUrls.push(url);
          }
        );
      }
      Vue.$toast.success("LR images uploaded");

      firebase.db.collection("oem_orders")
        .doc(`${invoiceDetail.order_id}`)
        .set({
          invoiceDetail: firebase2.firestore.FieldValue.arrayUnion(
            {
              delivery_date: invoiceDetail.delivery_date,
              bill_number: invoiceDetail.bill_number,
              lr_date: invoiceDetail.lr_date,
              lr_number: invoiceDetail.lr_number,
              materialList: invoiceDetail.materialList,
              truck_number: invoiceDetail.truck_number,
              driver_name: invoiceDetail.driver_name,
              driver_number: invoiceDetail.driver_number,
              attachments: uploadedUrls,
              eway_attachments: eway_uploadedUrls,
              lr_attachments: lr_uploadedUrls,
              invoice_date: new Date(),
            })
        }, { merge: true })
        .then(() => {

          console.log('Invoice Detail saved successfully')
          //state.commit("createDealerOrder", multiOemOrder);
          //resolve(order_data);
        })
        .catch((error) =>
          console.error("Error adding order document: ", error)
        );
    },
    imageUpload(context, { image: image }) {
      console.log(image)
      return new Promise((resolve) => {

        let timstm = Date.now()
        let file_path = 'grn_rejection/' + timstm + '/' + image.name;
        var uploadTask = firebase.storageRef.child(file_path).put(image);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          `state_changed`,
          (snapshot) => {
            // Get task progress,
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");

          },
          (error) => {
            console.log("-- Error in uploading ---", error.message);
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at", downloadURL);
              resolve(downloadURL);
            });
          }
        );
      });
    },
    imageUploadGeneric(context, { image: image }) {
      console.log(image)
      return new Promise((resolve) => {
        console.log(image.name);
        let timstm = Date.now();
        let file_path = 'attachment_upload/' + timstm + '/'+image.name ;
        var uploadTask = firebase.storageRef.child(file_path).put(image);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          `state_changed`,
          (snapshot) => {
            // Get task progress,
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");

          },
          (error) => {
            console.log("-- Error in uploading ---", error.message);
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at", downloadURL);
              resolve(downloadURL);
            });
          }
        );
      });
    },
    uploadInvoiceExcelParse(context, { objectList, withIndex }) {
      // console.log(objectList);
      return new Promise((resolve) => {
        let errorList = "";
        const responseList = [];
        objectList.forEach((object, index) => {
          let line = "";
          if (withIndex) {
            line = "Row " + (index + 1) + ": ";
          }
          // offer_name
          if (object["material_number"] == null) {
            errorList += "</br>" + line + "material_number is mandatory.";
          }
          if (object["serialNumber"] == null) {
            errorList += "</br>" + line + "serialNumber is mandatory.";
          }
          if (object["model"] == null) {
            errorList += "</br>" + line + "model is mandatory.";
          }
          if (object["batteryNumber"] == null) {
            errorList += "</br>" + line + "Battery Number is mandatory.";
          }
          if (object["batteryChemistry"] == null) {
            errorList += "</br>" + line + "Battery Chemistry is mandatory.";
          }
          if (object["batteryDesc"] == null) {
            errorList += "</br>" + line + "Battery Description is mandatory.";
          }
          if (object["batteryMaker"] == null) {
            errorList += "</br>" + line + "Battery Maker is mandatory.";
          }
          if (object["batteryCapacity"] == null) {
            errorList += "</br>" + line + "Battery Capacity is mandatory.";
          }
          if (object["batteryPacks"] == null) {
            errorList += "</br>" + line + "Number of Battery Packs is mandatory.";
          }
          if (object["chargerNumber"] == null) {
            errorList += "</br>" + line + "Charger Number is mandatory.";
          }
          if (object["controllerNumber"] == null) {
            errorList += "</br>" + line + "Controller Number is mandatory.";
          }
          if (object["converterNumber"] == null) {
            errorList += "</br>" + line + "Converter Number is mandatory.";
          }
          if (object["motorNumber"] == null) {
            errorList += "</br>" + line + "Motor Number is mandatory.";
          }
          if (object["subsidy"] == null) {
            errorList += "</br>" + line + "Subsidy is mandatory.";
          }
          responseList.push(object);
        });
        if (errorList == "") {
          resolve(responseList);
        } else {
          resolve("CSV upload failed </br>" + errorList);
        }
      });
    }
  },
};
