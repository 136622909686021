import oemConfigController from "./projectConfig/oemConfigController";
import oemInventoryController from "./oemInventoryController";
import oemClaimsController from "./oemClaimsController";
import oemOrderController from "./oemOrderController";
import oemReloadDataController from "./oemReloadDataController"

export default {
  modules: {
    oemConfigController,
    oemInventoryController,
    oemOrderController,
    oemClaimsController,
    oemReloadDataController,
  },
};
