function getProjectTheme() {
  let primaryColor = "#CD193D";
  let secondaryColor = "#9F0725";
  let backgroundColor = "#E8EAF6";
  switch (process.env.VUE_APP_NAME) {
    case 'boom': { primaryColor = "#11887A"; secondaryColor = "#24CC63"; backgroundColor = "#E5DED6"; break; }
    case 'greaves': { primaryColor = "#1D88FB"; secondaryColor = "#9F0725"; backgroundColor = "#E8EAF6"; break; }
  }

  return {
    themes: {
      light: {
        primary: primaryColor,
        secondary: secondaryColor,
        anchor: primaryColor,
        background: backgroundColor,
      },
      dark: {
        primary: primaryColor,
        secondary: secondaryColor,
        anchor: primaryColor,
        background: backgroundColor,
      },
    },
    options: {
      customProperties: true
    },
  }
}

export default {
  getProjectTheme
};