import store from "@/store";
import firebase from "./configFirebase";

function update(tableName, id, obj) {
  return new Promise((resolve) => {
    console.log("update");
    obj.updated_at = new Date();
    firebase.db
      .doc(tableName + `/${id}`)
      .set(obj, { merge: true })
      .then(() => {
        resolve(true);
        console.log("Document successfully updated!", tableName, obj);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  });
}

function create(tableName, obj) {
  console.log("create");
  obj.created_at = new Date();
  obj.updated_at = new Date();
  return firebase.db.collection(tableName).add(obj);
}
function query(
  collectionName,
  whereObjList,
  orderBy,
  reverse,
  limit,
  startAfter
) {
  console.log(
    "query :" +
      collectionName +
      " : " +
      whereObjList +
      " : " +
      orderBy +
      " : " +
      reverse +
      " : " +
      limit
  );

  return new Promise((resolve) => {
    let abc = firebase.db.collection(collectionName);
    for (let whereObj of whereObjList) {
      if (whereObj.length == 2) {
        whereObj.push("==");
      }
      {
        abc = abc.where(whereObj[0], whereObj[2], whereObj[1]);
      }
    }
    if (orderBy != null) {
      let direction = "asc";
      if (reverse == true) {
        direction = "desc";
      }
      abc = abc.orderBy(orderBy, direction);
    }

    if (limit != null) {
      if (startAfter != null) {
        abc = abc.startAfter(startAfter);
      }
      abc = abc.limit(limit);
      finalGet(abc, collectionName, limit, orderBy).then((objectList) => {
        resolve(objectList);
      });
      // });
    } else {
      finalGet(abc, collectionName, limit, orderBy).then((objectList) => {
        resolve(objectList);
      });
    }
  });
}
function finalGet(abc, collectionName, limit, orderBy) {
  return new Promise((resolve) => {
    abc.get().then((querySnapshot) => {
      let objectList = [];
      querySnapshot.forEach((doc) => {
        const object = doc.data();
        object.id = doc.id;
        objectList.push(object);
      });

      if (limit) {
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        if (lastVisible && lastVisible.data()) {
          store.commit(
            collectionName + "_startAfterMutation",
            lastVisible.data()[orderBy]
          );
        }       
      }
      resolve(objectList);
    });
  });
}

function getRowById(collectionName, id) {
  console.log(collectionName + " getRowById : " + id);

  return new Promise((resolve) => {
    firebase.db
      .doc(collectionName + `/${id}`)
      .get()
      .then((response) => {
        if (response && response.id) {
          let data = response.data();
          data.id = response.id;
          resolve(data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        resolve(null);
      });
  });
}

function saveObject(collectionName, documentObject) {
  console.log("firebaseObject : " + collectionName);
  return new Promise((resolve) => {
    if (documentObject.id == null) {
      console.log(collectionName + " create");
      create(collectionName, documentObject).then((ref) => {
        documentObject.id = ref.id;
        resolve(documentObject);
      });
    } else {
      console.log(collectionName + " update : " + documentObject.id);
      update(collectionName, documentObject.id, documentObject).then(() => {
        resolve(documentObject);
      });
    }
  });
}

function deleteObject(collectionName, documentObject) {
  if (documentObject != null && documentObject.id != null) {
    console.log(collectionName + " delete : " + documentObject.id);
    return firebase.db.doc(collectionName + `/${documentObject.id}`).delete();
  } else {
    console.log("no id found ");
  }
}

export default {
  query,
  getRowById,
  saveObject,
  deleteObject,
  update,
  create,
};
