
export default {
  state: {
    project: 'ampere',
    // project : 'aoom',
    // project : 'Ampere',
  },
  getters: {
    getTheme(state){
      let projectFolder = require("./"+state.project+"-project");
      return projectFolder.getProjectTheme();
    }
  },
  actions: {

  },
};