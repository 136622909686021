import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import VueToast from "vue-toast-notification";
import "vuetify/dist/vuetify.min.css";
import "./assets/css/custom.css";
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";
import "vue-toast-notification/dist/theme-sugar.css";
import "./assets/css/custom.css";
import axios from "axios";
import VueAxios from "vue-axios";
import theme from "./modules/oem/controller/projectConfig/theme/theme.js"
Vue.config.productionTip = false;
Vue.use(Vuetify);
Vue.use(VueToast, {
  // One of the options
  position: "top",
  duration: 8000,
});
Vue.use(VueAxios, axios);

var converter = require("number-to-words");
// var projectConfig = require("")

Vue.filter("toWords", function (value) {
  if (!value) return "";
  return converter.toWords(value).toUpperCase();
});
new Vue({
  render: (h) => h(App),
  router,
  store,
  vuetify: new Vuetify({
    theme: theme.getProjectTheme()
  }),
}).$mount("#app");
