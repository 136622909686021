<template>
  <v-dialog :value.sync="loaderTitle" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text @click="clickClose()">
        {{ loaderTitle }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      loaderTitle: (state) => state.loaderTitle,
    }),
  },
  methods:{
    clickClose(){
          this.$store.commit("showLoader", null);
    }
  }
};
</script>
