import localForageService from "./localForageService";
import configFirebase from "./configFirebase";
import firestoreService from "./firestoreService";
import store from "@/store";

function getItem(key) {
  return new Promise((resolve) => {
    localForageService.getItem(key).then((value) => {
      resolve(value);
    });
  });
}
function setItem(key, value) {
  return new Promise((resolve) => {
    localForageService.setItem(key, value).then(() => {
      resolve(true);
    });
  });
}
function deleteObject(tableName, doc) {
  console.log("deleteObject : " + tableName);
  return new Promise((resolve) => {
    getItem(tableName).then((oldData) => {
      let object = oldData.find((x) => x.id == doc.id);
      if (object != null) {
        oldData.splice(oldData.indexOf(object), 1);
        setItem(tableName, JSON.parse(JSON.stringify(oldData))).then(() => {
          store.commit("mutate" + tableName, oldData);
          firestoreService.deleteObject(tableName, doc);
          resolve(true);
        });
      }
    });
  });
}

function saveInTable(tableName, newObject) {
  console.log("saveInTable : " + tableName);
  return new Promise((resolve) => {
    let newObjectList = [];
    newObjectList.push(newObject);
    saveRowsInTable(tableName, newObjectList).then((response) => {
      resolve(response);
    });
  });
}

function saveRowsInTable(tableName, newObjectList) {
  console.log("saveRowsInTable : " + tableName);
  return new Promise((resolve) => {
    getItem(tableName).then((oldData) => {
      if (oldData == null) {
        oldData = [];
      }
      newObjectList.forEach((doc) => {
        let docRef;
        console.log(tableName + " " + doc.id);
        if (doc.id == null) {
          docRef = configFirebase.db.collection(tableName).doc(); //automatically generate unique id
          doc.id = docRef.id;
          doc.active = true;
          doc.created_at = new Date();
          doc.newData = true;
        } else {
          doc.newData = false;
          docRef = configFirebase.db.collection(tableName).doc(`/${doc.id}`);
          removeObjById(oldData, doc.id);
        }
        doc.updated_at = new Date();
        oldData.unshift(doc);
      });
      setItem(tableName, oldData).then(() => {
        store.commit("mutate" + tableName, oldData);
        for (let doc of newObjectList) {
          // if (doc.newData) {
          //   firestoreService.create(tableName, doc);
          // } else {
            firestoreService.update(tableName, doc.id, doc);
          // }
        }
        resolve(true);
      });
    });
  });
}

function getRows(tableName, whereClauseList = [], onlyWhereClause = false) {
  return new Promise((resolve) => {
    getItem(tableName).then((localDataList) => {
      if (localDataList == null) {
        localDataList = [];
      }
      localDataList = localDataList.filter((x) => x.active != false);
      store.commit("mutate" + tableName, localDataList);
      getItem(tableName + "_updated_at").then((updated_at) => {
        let orderBy = null;
        let firstCall = updated_at == null;
        onlyWhereClause = true; // only time being, because we data is inconsistant, remove this line when data get corrected
        if (onlyWhereClause == false) {
          let operator = ">=";
          if (updated_at == null) {
            updated_at = new Date(0);
          } else {
            operator = ">";
          }
          orderBy = "updated_at";
          console.log("getRows : " + tableName + " : " + updated_at);

          let updatedAtWhere = ["updated_at", updated_at, operator];
          whereClauseList.push(updatedAtWhere);
        }
        firestoreService
          .query(tableName, whereClauseList, orderBy, !onlyWhereClause, null)
          .then((dataList) => {
            if (dataList != null) {
              updated_at = 0;
              for (let data of dataList) {
                if (data.updated_at > updated_at) {
                  updated_at = data.updated_at;
                }

                // localDataList.unshift(data);
                const abc = localDataList.find((x) => x.id == data.id);
                if (abc == null) {
                  localDataList.unshift(data);
                } else {
                  localDataList.splice(localDataList.indexOf(abc), 1, data);
                }
              }
              // for (let localData of localDataList) {
              //   for (let key in localData) {
              //     if (localData[key] && localData[key].toDate) {
              //       localData[key] = localData[key].toDate();
              //     }
              //   }
              // }
              convertToDate(localDataList);

              setItem(tableName, localDataList).then(() => {
                setItem(tableName + "_updated_at", updated_at).then(() => {
                  store.commit("mutate" + tableName, localDataList);

                  if (firstCall == true) {
                    resolve(localDataList);
                  }
                });
              });
            }
          });
        if (updated_at != null) {
          resolve(localDataList);
        }
      });
    });
  });
}

function convertToDate(conversionObject) {
  if (Array.isArray(conversionObject)) {
    for (let arrayObject of conversionObject) convertToDate(arrayObject);
  } else {
    if (typeof conversionObject == "object") {
      for (let key in conversionObject) {
        if (conversionObject[key] && conversionObject[key].toDate) {
          // console.log("toDate : " + key);
          conversionObject[key] = conversionObject[key].toDate();
        } else if (typeof conversionObject[key] == "object") {
          convertToDate(conversionObject[key]);
        }
      }
    }
  }
}

function removeObjById(localList, id) {
  const abc = localList.find((x) => x.id == id);
  if (abc != null) {
    localList.splice(localList.indexOf(abc), 1);
  }
}

export default {
  getItem,
  setItem,
  getRows,
  saveInTable,
  saveRowsInTable,
  deleteObject,
};
