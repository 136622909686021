import localForageService from "@/services/localForageService";
export default {
  getters: {
    format_date(value) {
      if (value.seconds != null) {
        resolve(new Date(value.seconds * 1000));
      } else {
        resolve(new Date(value));
      }
    },
  },
  actions: {
    displayDate({}, date) {
      return new Promise((resolve) => {
        let month = Number(date.getMonth() + 1);
        let day = date.getDate();

        let dispay = date.getFullYear() + "-";
        if (month > 9) {
          dispay += month + "-";
        } else {
          dispay += "0" + month + "-";
        }
        if (day > 9) {
          dispay += day;
        } else {
          dispay += "0" + day;
        }
        console.log("dispay : " + dispay);
        resolve(dispay);
      });
    },
    removeObjById({}, localList, id) {
      const abc = localList.find((x) => x.id == id);
      if (abc != null) {
        localList.splice(localList.indexOf(abc), 1);
      }
    },

    getObject({}, object) {
      return new Promise((resolve) => {
        localForageService.getItem(object.tableName).then((rows) => {
          let found = rows.find((x) => x.id == object.id);
          resolve(found);
        });
      });
    },
    // format_date({}, value) {
    //   return new Promise((resolve) => {
    //     if (value.seconds != null) {
    //       resolve(new Date(value.seconds * 1000));
    //     } else {
    //       resolve(new Date(value));
    //     }
    //   });
    // },
  },
};
