import Vue from "vue";
import Vuex from "vuex";
import authController from "@/modules/auth/controller/authController";
import changePasswordController from "@/modules/auth/controller/changePasswordController";
import forgetPasswordController from "@/modules/auth/controller/forgetPasswordController";
import oemController from "@/modules/oem/controller/oemController";
import utilityController from "./utilityController";
import { Alert } from "@/commonModel/Alert.js";
import VuexPersistence from "vuex-persist";
import localForageService from "../services/localForageService";
import firebase from "../services/configFirebase";
import axios from "axios";

const vuexLocal = new VuexPersistence({
  supportCircular: true,
  asyncStorage: true,
  storage: localForageService,
});
Vue.use(Vuex);

function getAllRows(collectionName) {
  return new Promise((resolve) => {
    firebase.db.collection(collectionName).onSnapshot((querySnapshot) => {
      let objectList = [];
      querySnapshot.forEach((doc) => {
        const object = doc.data();
        object.id = doc.id;
        objectList.push(object);
      });
      console.log("query response : " + objectList.length);
      resolve(objectList);
    });
  });
}
export const store = new Vuex.Store({
  modules: {
    authController,
    changePasswordController,
    forgetPasswordController,
    oemController,
    utilityController,
  },
  state: {
    loaderTitle: null,
    file_upload_dialog: false,
    alert: new Alert(),
    order_types: {
      data: null,
    },
    newOrderCode: "",
    orderType: "",
    gradingObject: {},
    currrent_oem: null
  },
  getters: {
    file_upload_dialog_state(state) {
      return state.file_upload_dialog;
    },
  },
  mutations: {
    SET_FILE_UPLOAD_DIALOG(state, dialog_state) {
      state.file_upload_dialog = dialog_state;
    },
    showLoader(state, text) {
      state.loaderTitle = text;

      setTimeout(function () {
        state.loaderTitle = null;
      }, 30000);
    },
    updateAlert(state, alert) {
      state.alert = alert;
    },
    SET_ORDER_TYPE(state, order_types) {
      state.order_types.data = order_types;
    },
    setGradingObject(state, obj) {
      console.log("setGradingObject", obj);
      state.gradingObject = obj;
    },
    setCurrentOem(state, oem) {
      state.currrent_oem = oem;
    },    
  },
  actions: {
    updateLastSync({}, collectionName) {
      getAllRows(collectionName).then((objectList) => {
        console.log("objectList  :" + objectList.length);
        for (let doc of objectList) {
          firebase.db.doc(collectionName + `/${doc.id}`).set(doc);
        }
      });
    },

    showAlert(context, alert) {
      if (alert.duration == null) {
        alert.duration = 30000;
      }
      alert.showAlert = true;
      context.commit("updateAlert", alert);
      setTimeout(() => {
        alert.showAlert = false;
        context.commit("updateAlert", alert);
      }, alert.duration);
    },
    fetch_appscript_dealer_list() {
      return new Promise((resolve) => {
        let url = "https://script.google.com/macros/s/AKfycbxj1vs7Q58UZrTx48DEPijxX1vv26DZ_UffW7RR5510jb77arWl8yfACbmfzfGbTOOT/exec";
        if (process.env.VUE_APP_NAME == 'greaves') {
          url = "https://script.google.com/macros/s/AKfycbxfeYasS7BXl-vkRPTYvvNX3Lphd3L6uxu1EO-hyVlBVVNzgXr1hn8Wa9YLAJ_yogg5/exec"
        }
        
        axios
          .get(
            url
          )
          .then((response) => {
            // console.log(JSON.stringify(response.data.data));
            localForageService
              .setItem("EXCEL_DMS_USER_DATA", response.data.data)
              .then(() => {
                console.log(
                  "EXCEL_DMS_USER_DATA : " + response.data.data.length
                );
                resolve(response.data.data);
              });
          });
      });
    },
    fileUpload(context, fileUploadObject) {
      return new Promise((resolve) => {
        let uploadedUrls = [];
        console.log("fileUploadObject : " + JSON.stringify(fileUploadObject));
        for (let i = 0; i < fileUploadObject.attached_file.length; i++) {
          if (attached_file[i] != "") {
            image_file = attached_file[i];
            let fileName =
              fileUploadObject.fileName + image_file.name + new Date();
            console.log("file to upload : " + fileName);
            firebase.storageRef
              .child(fileName)
              .put(image_file)
              .then((snapshot) => {
                uploadedUrls.push(snapshot.ref.getDownloadURL());
                if (attached_file.length == i + 1) {
                  console.log("uploadedUrls : " + uploadedUrls);
                  resolve(uploadedUrls);
                }
              });
          }
        }
      });
    }
  },
  plugins: [vuexLocal.plugin],
});

export default store;
