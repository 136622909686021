import localDbService from "@/services/localDbService";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

function guardMyroute(to, from, next) {
  localDbService.getItem("loggedInUser").then((currentUser) => {
    
    if (currentUser != null) {
      if (process.env.VUE_APP_NAME == 'greaves') {
        currentUser.role = currentUser.apps;
      }
  
      if (currentUser.role && currentUser.role.includes("OEM")) {
        if (currentUser.role.includes(to.meta.moduleName)) {
          store.commit("mutateLoggedInUser", currentUser);
          console.log("currentUser.role : " + currentUser.role);
          next();
        } else {
          next(history.back());
        }
      }else{
        Vue.$toast.error("Only OEM can login");
        next({ name: "SignIn" });  
      }
    } else {
      // console.log("currentUser unavailable ");
      Vue.$toast.error("Can't access POS portal");
      next({ name: "SignIn" });
    }
  });
}
function guardSignIn(to, from, next) {
  localDbService.getItem("loggedInUser").then((currentUser) => {

    if (currentUser != null && to.meta == 'SignIn') {
      if (process.env.VUE_APP_NAME == 'greaves') {
        currentUser.role = currentUser.apps;
      }

      if (currentUser.role.includes("POS")) {
        next({ name: "PosDashboard" });
      }
    } else {
      next();
    }
  });
}
function setDashboard(to, from, next) {
  localDbService.getItem("loggedInUser", null).then((currentUser) => {

    if (currentUser != null) {
      if (process.env.VUE_APP_NAME == 'greaves') {
        currentUser.role = currentUser.apps;
      }
      if (currentUser.role && currentUser.role.includes("OEM")) {
        store.commit("mutateLoggedInUser", currentUser);
        next({ name: "OemDashboard" });
      } else {
        next({ name: "SignIn" });
      }
    } else {
      next({ name: "SignIn" });
    }
  });
}
const routes = [
  {
    path: "/",
    beforeEnter: setDashboard,
  },
  {
    path: "/Profile",
    name: "Profile",
    component: () => import("../modules/auth/views/profile/Profile.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/OemDashboard",
    name: "OemDashboard",
    component: () => import("../modules/oem/views/dashboard/OemDashboard.vue"),
    beforeEnter: guardMyroute,
    meta: { moduleName: "OEM" },
  },
  {
    path: "/privacy_policy",
    name: "PrivacyPolicy",
    component: () => import("../modules/auth/views/signIn/PrivacyPolicy.vue"),
  },
  {
    path: "/SignIn",
    name: "SignIn",
    component: () => import("../modules/auth/views/signIn/SignIn.vue"),
    beforeEnter: guardSignIn,
  },
  {
    path: "/forgetPassword",
    name: "ForgetPassword",
    component: () =>
      import("../modules/auth/views/forgetPassword/ForgetPassword.vue"),
  },
  {
    path: "/oem/claims",
    name: "OEMClaims",
    component: () => import("@/modules/oem/views/claims/OemClaims.vue"),
    beforeEnter: guardMyroute,
    meta: { moduleName: "OEM" },
  },
  {
    path: "/oem/OemClaimsWarrantyDetails/:id",
    name: "OemClaimsWarrantyDetails",
    component: () =>
      import(
        "@/modules/oem/views/claims/warranty/warrantyDetails/OemClaimsWarrantyDetails.vue"
      ),
    beforeEnter: guardMyroute,
    meta: { moduleName: "OEM" },
  },

  {
    path: "/oem/OemClaimsServiceDetails/:id",
    name: "OemClaimsServiceDetails",
    component: () =>
      import(
        "@/modules/oem/views/claims/services/serviceDetails/OemClaimsServiceDetails.vue"
      ),
    beforeEnter: guardMyroute,
    meta: { moduleName: "OEM" },
  },
  {
    path: "/oem/orders",
    name: "OemOrders",
    component: () =>
      import("@/modules/oem/views/orders/OemOrders.vue"),
    beforeEnter: guardMyroute,
    meta: { moduleName: "OEM" },
  },
  {
    path: "/oem/order/order_detail/:id",
    name: "OemOrderDetail",
    component: () =>
      import("@/modules/oem/views/orders/details/OrderDetails.vue"),
    beforeEnter: guardMyroute,
    meta: { moduleName: "OEM" },
  },
  {
    path: "/oem/orderStatus",
    name: "OemOrderStatus",
    component: () =>
      import("@/modules/oem/views/orders/status/OemOrderStatus.vue"),
    beforeEnter: guardMyroute,
    meta: { moduleName: "OEM" },
  },

  /* OEM */
  {
    path: "/oem/inventoryinfo",
    name: "InventoryInfo",
    component: () => import("@/modules/oem/views/inventory/InventoryInfo.vue"),
    beforeEnter: guardMyroute,
    meta: { moduleName: "OEM" },
  }, 
  {
    path: "/oem/moqCategory",
    name: "moqCategory",
    component: () => import("@/modules/oem/views/inventory/moq/moqCategory.vue"),
    beforeEnter: guardMyroute,
    meta: { moduleName: "OEM" },
  },  
];
const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("showLoader", null);
   localDbService.getItem("loggedInUser", null).then((currentUser) => {
     if (currentUser != null && currentUser.role && currentUser.role.includes("OEM")) {
       if (to.meta.moduleName !== 'OEM') {
         next({ name: "OemDashboard" });
       } else {
         next();
       }
     } else {
       next();
     }
   });
});
export default router;
