import localDbService from "@/services/localDbService";
import firebase from "@/services/configFirebase";
import store from "@/store/index";

export default {
  state:{
      versions : null
  },
  mutations: {
    setVersions(state, versions)
    {
        state.versions = versions
    },    
  },
  getters: {
        
  },
  actions: {
   async getVersions({commit},{id})
    {      
        var docRef = firebase.db.collection("config").doc(id);
        const doc = await docRef.get()       
          if (doc.exists) {
              console.log("Web Versions:", doc.data());              
              commit('setVersions',doc.data())
          }        
    },    
  },
};