import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import store from "../store";

import localDbService from "@/services/localDbService";
import router from "../router";
require("firebase/auth");

export const config = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const WARRANTY_DASHBOARD_URL = process.env.VUE_APP_WARRANTY_DASHBOARD_URL; // warranty dashboard
const SALES_DASHBOARD_URL = process.env.VUE_APP_SALES_DASHBOARD_URL; //sales dashboard

// ************************

firebase.initializeApp(config);

// firebase.auth().onAuthStateChanged(function (user) {
//   if (user) {
//     firebase
//       .auth()
//       .currentUser.getIdTokenResult()
//       .then((idToken) => {
//         if (process.env.VUE_APP_NAME == 'greaves') {
//           idToken.claims.role = idToken.claims.apps;
//           idToken.claims.customer = idToken.claims.dealerCode;
//         }
//         localDbService.setItem("loggedInUser", idToken.claims).then(() => {
//           store.commit("mutateLoggedInUser", idToken.claims);
//         });
//       });
//   } else {
//     console.log("removing user : ");
//     localDbService.setItem("loggedInUser", null).then(() => {
//       console.log("logout ");
//       store.commit("mutateLoggedInUser", null);
//       router.replace({ name: "SignIn" });
//     });
//   }
// });

// Initialize Cloud Firestore through Firebase
let db = firebase.firestore();
let storageRef = firebase.storage().ref();
let functions = firebase.functions();

// Initialize constant global variables
const product_image_base_url = `https://firebasestorage.googleapis.com/v0/b/${config.projectId}.appspot.com/o`;
const BASE_REST_URL = `https://asia-south1-${config.projectId}.cloudfunctions.net`; //Change this variable according to the enviornment

// Disable deprecated features
db.settings({
  timestampsInSnapshots: true,
});
// db.enablePersistence({ experimentalTabSynchronization: true });
export default {
  db,
  storageRef,
  functions,
  product_image_base_url,
  BASE_REST_URL,
  WARRANTY_DASHBOARD_URL,
  SALES_DASHBOARD_URL,
};
